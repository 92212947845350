"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findIn, findAllIn,removeClass, on, addClass} from '@elements/dom-utils';
import {add} from '@elements/scroll-animations';
import {EVENTS} from '@elements/affix';

const defaultOptions = {};

const defaultSelectors = {
    base: '.js-experience-area',
    affix: '.js-experience-area__affix-container',
    inner: '.js-experience-area__inner',
    title: '.js-experience-area__title'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createExperience(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createExperience(baseElement, options, selectors) {

    let affixContainer = findIn(selectors.affix, baseElement);
    let affixTitle = findAllIn(selectors.title, baseElement);
    let innerContainer = findIn(selectors.inner, baseElement);

    function setAffixHandler(evt) {
        addClass('experience-area--is-active', document.body);
    }

    function unsetAffixHandler(evt) {
        removeClass('experience-area--is-active', document.body);
    }

    on(EVENTS.AFFIX, setAffixHandler, affixContainer);
    on(EVENTS.DETACH, unsetAffixHandler, affixContainer);


    add(
        affixTitle,
        calculateAnimationProgress,
        setAnimationProgress
    );

    // gets element -> returns number between 0 and 1
    function calculateAnimationProgress(element) {
        const start = window.innerHeight;
        const end = window.innerHeight/ 10;

        return {
            opacity:  Math.max(
                (innerContainer.getBoundingClientRect().top - end) / (start - end),
                0.08
            )
        };
    }

// gets element & progress (number between 0 - 1) -> sets style of element
    function setAnimationProgress(element, progress) {
        element.style.opacity = progress.opacity;
    }
}

