"use strict";

import initModulesInScope, {onFind, cleanUpInScope} from "@elements/init-modules-in-scope";
import {on, findIn, empty} from "@elements/dom-utils";
import {translate} from '@elements/translations';

const defaultSelectors = {
    base: '.js-quickfinder',
    childInput: '.js-quickfinder__child-input',
    childContainer: '.js-quickfinder__childs',
    adultInput: '.js-quickfinder__adult-input',
    personsContainer: '.js-quickfinder__persons',
};

export function init(selectors = defaultSelectors, template) {
    onFind(selectors.base, function (baseElement) {
        createQuickfinder(
            baseElement,
            {...defaultSelectors, ...selectors},
            renderChildAgeInput
        );
    });

    function renderChildAgeInput({element, index, value, onChange}) {
        let markupElement = document.createElement('div');

        let markup = `<div class="" id="quickfinder-child-age-${index}">` +
            `<div class="select form-group has-value">` +
            `<div class="position-relative">` +
            `<label class="form-control-label floating-label" for="childAge${index}"> ${translate('form.childAge')} ${index}</label>` +
            `<select id="childAges${index}" name="childAge${index}[]" class="form-control js-quickfinder__child-age-input js-floating-label has-value">` +
            `<option  value="0" selected>0 ${translate('form.Years')}</option>`;

        for (let i = 1; i <= 14; i++) {
            markup += `<option value="${i}" ${i === value ? 'selected': ''}>${i} ${translate('form.Years')}</option>`;
        }

        markup += `</select>` +
            `</div>`+
            `<div class="form-errors invalid-feedback"></div>` +
            `</div></div>`;

        markupElement.innerHTML = markup;
        let quickfinderItem = markupElement.childNodes[0];    //remove wrapper-div

        quickfinderItem.addEventListener('change', function(e) {
            onChange(e.target.value);
        });

        return quickfinderItem;
    }
}


export function createQuickfinder(quickfinder, selectors = defaultSelectors, template) {
    selectors = {
        ...defaultSelectors,
        ...selectors
    };

    let valuesByIndex = {},
        childContainer = findIn(selectors.childContainer, quickfinder),
        childInput = findIn(selectors.childInput, quickfinder),
        adultInput = findIn(selectors.adultInput, quickfinder),
        personContainer = findIn(selectors.personsContainer, quickfinder),
        personValue = parseInt(personContainer.innerHTML);

    on('change', function(evt) {
        cleanUpInScope(childContainer);
        empty(childContainer);

        for (let i = 1; i <= evt.target.value; i++) {
            childContainer.appendChild(template({
                    element: quickfinder,
                    index: i,
                    value: valuesByIndex[i],
                    onChange: function (value) {
                        valuesByIndex[i] = value;
                    }
                })
            );
        }

        initModulesInScope(findIn(selectors.childInput, quickfinder));

        if(evt.target.value > 0){
            childContainer.removeAttribute('hidden');
        }else{
            childContainer.setAttribute('hidden', 'hidden');
        }

        calculatePersons();

    }, childInput);



    on('change', function(evt) {
        calculatePersons()
    }, adultInput);


    function calculatePersons(){
        let childInputValue = childInput.value;
        if (isNaN(childInputValue) || childInputValue === '' || childInputValue === 0) {
            childInputValue = 0;
        }
        personContainer.innerHTML = parseInt(childInputValue) + parseInt(adultInput.value);
    }

}

