import {onFind} from "@elements/init-modules-in-scope";
import {closest, findAll, on} from '@elements/dom-utils';

const selectors = {
    base: '.js-set-datepicker-range',
    rangeDatepicker: '.js-set-datepicker-range__datepicker'
};

export function init () {
    onFind(selectors.base, function (baseElement) {
        let isSelect = baseElement.tagName === 'SELECT' || false;
        
        if(isSelect) {
            on('change', function (evt) {
                let selectedOption = evt.target.options[evt.target.selectedIndex];
                let newMinSelectableDays = selectedOption.dataset.setDatepickerRangeMinSelectableDays;
                let newMaxSelectableDays = selectedOption.dataset.setDatepickerRangeMaxSelectableDays;
                let target = closest(selectors.rangeDatepicker, baseElement);

                setDateChanges(newMinSelectableDays, newMaxSelectableDays, target, true);
            }, baseElement);
        }
    });
}

function setDateChanges(newMinSelectableDays, newMaxSelectableDays, target, resetDate) {
    if(newMinSelectableDays || newMaxSelectableDays && target) {
        if(newMinSelectableDays) {
            target.setAttribute('data-datepicker-range-min-selectable-days', newMinSelectableDays);
        }
        if(newMaxSelectableDays) {
            target.setAttribute('data-datepicker-range-max-selectable-days', newMaxSelectableDays);
        }
    }
}