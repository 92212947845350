"use strict";

import {on, findAllIn, findIn} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import {trackEvent} from '@elements/tracking/src/gtm';

const defaultSelectors = {
    base: '.js-tracking-quickfinder',
    button: '.js-tracking-quickfinder__button',
    actionEl: '.js-tracking-quickfinder__input-action',
    labelEl: '.js-tracking-quickfinder__input-label'
};

export function init(selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createTrackingQuickfinder(
            baseElement,
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createTrackingQuickfinder(baseElement, selectors) {
    let button = findIn(selectors.button, baseElement);
    let categoryValue = baseElement.getAttribute('data-tracking-quickfinder-category') || '';
    let actionEl = findIn(selectors.actionEl, baseElement);
    let labelEl = findIn(selectors.labelEl, baseElement);


    if(button) {
        // winter quickfinder
        on('click', function(evt) {
            let actionValue = actionEl ? actionEl[actionEl.selectedIndex].text : "";
            trackEvent(
                {
                    category: categoryValue,
                    action: actionValue,
                }, {debug: true})
        }, button);
    }

    // summer quickfinder
    on('submit', function(evt) {
        let actionValue = actionEl ? findAllIn('input:checked', actionEl).map(el => el.dataset.label).join(', ') : "";
        let labelValue = labelEl ? findAllIn('input:checked', labelEl).map(el => el.dataset.label).join(', ') : "";

        trackEvent(
            {
                category: categoryValue,
                action: actionValue,
                label: labelValue
            }, {debug: true})
    }, baseElement);


}
