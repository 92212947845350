"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findAllIn} from "@elements/dom-utils";

export function init () {
    if (!matchMedia('(min-width: 768px) and (pointer: fine)').matches) {
        return;
    }

    onFind('.js-follow-mouse-animation', function (baseElement) {
        let items = findAllIn('.js-follow-mouse-animation__item', baseElement);

        let mouseX, mouseY;
        executeFrame();

        function executeFrame() {
            requestAnimationFrame(executeFrame);
            followMouse();
        }

        function followMouse() {
            items.forEach(function(el) {
                let rotationValue = mouseX / 15;
                let translateValue = mouseY/13;
                let scaleValue = mouseY/1000 + 0.9;
                el.style.transform = "rotate("  + rotationValue + "deg) translateY("+ translateValue +"px) scale("+ scaleValue +")";
            })
        }

        baseElement.addEventListener("mousemove", function (evt) {
            mouseX = evt.clientX/window.innerWidth  * 100 - 50;
            mouseY = evt.clientY/window.innerHeight  * 100;
        });
    });

}