"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findIn, on, trigger, closest} from "@elements/dom-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import {getApi} from "./custom-datepicker";

const dayInMilliseconds = 24 * 60 * 60 * 1000;

const defaultOptions = {
    openDatepickerTo: false
};

const defaultSelectors = {
    base: '.js-datepicker-range',
    datepickerFrom: '.js-datepicker-range__from',
    datepickerTo: '.js-datepicker-range__to'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createRangeDatepicker(
            baseElement,
            options,
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createRangeDatepicker(datepicker, options, selectors) {
    let from = findIn(selectors.datepickerFrom, datepicker);
    let to = findIn(selectors.datepickerTo, datepicker);

    Promise.all([getApi(from), getApi(to)]).then(function([fromApi, toApi]) {

        let fromInput = fromApi.getInput();

        on('change', function () {
            let rangeDatepicker = closest(selectors.base, from);

            let elementOptions = {
                ...options,
                ...getPrefixedDataSet('datepicker-range', rangeDatepicker)
            };

            if(elementOptions.defaultDuration) {
                toApi.setDate(roundDate(new Date(fromApi.getDate().getTime() + elementOptions.defaultDuration * dayInMilliseconds)));
            }

            if (fromApi.getDate()) {
                toApi.setMinDate(fromApi.getDate());
            }

            if(elementOptions.minSelectableDays && fromApi.getDate()) {
                toApi.setMinDate(roundDate(new Date(fromApi.getDate().getTime() + (+elementOptions.minSelectableDays-1) * dayInMilliseconds)));
            }

            if(elementOptions.maxSelectableDays && fromApi.getDate()) {
                toApi.setMaxDate(roundDate(new Date(fromApi.getDate().getTime() + (+elementOptions.maxSelectableDays-1) * dayInMilliseconds)));
            }

            if(elementOptions.openDatepickerTo){
                toApi.getDatepickerInstance().open();
            }

        }, fromInput);
    });
}

function roundDate(date) {
    if (date) {
        return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
    }
}
