import './scripts/common';
import {onFind} from "@elements/init-modules-in-scope";

import Tab from 'bootstrap/js/dist/tab';
onFind('[data-bs-toggle="tab"]',function (element) {
    new Tab(element);
});

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};


/**********************************
 *
 *      Site Stuff
 *
 * ********************************/

/*import * as lightbox from '@elements/lightbox';
lightbox.init();*/

import * as scrollingArea from './scripts/scrolling-area';
scrollingArea.init();

import * as affix from './scripts/affix';
affix.init();

import * as fadeIn from './scripts/fade-in';
fadeIn.init();

import * as followMouseAnimation from './scripts/follow-mouse-animation';
followMouseAnimation.init();

import * as setDatepicker from './scripts/set-datepicker';
setDatepicker.init();

import * as setDatepickerRange from './scripts/set-datepicker-range';
setDatepickerRange.init();

import * as formValidation from './scripts/form-validation';
formValidation.init();

import * as experienceArea from './scripts/experience-area';
experienceArea.init();

import * as datepicker from './scripts/custom-datepicker';
datepicker.init();

import * as floatingLabels from '@elements/floating-labels';
floatingLabels.init();

import * as shariff from './scripts/shariff';
shariff.init();

import * as datepickerRange from './scripts/custom-datepicker-range';
datepickerRange.init();

import * as quickfinder from './scripts/quickfinder';
quickfinder.init();

import * as dropdownPersist from './scripts/dropdown-persist';
dropdownPersist.init();

import * as playVideo from './scripts/play-video';
playVideo.init();

import * as trackingQuickfinder from './scripts/tracking-quickfinder';
trackingQuickfinder.init();

import * as ticketQuickfinder from './scripts/ticket-quickfinder';
ticketQuickfinder.init();

import * as ticketQuickfinderConfig from './scripts/tickets/ticket-quickfinder-config';
ticketQuickfinderConfig.init();