"use strict";

import {on,trigger,findAllIn,findIn} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-ticket-quickfinder', function (element) {
        let inputs = findAllIn('.js-multiple-select__input', element);
        let ticketQuickfinderTagContainer = findIn('.js-ticket-quickfinder__tags-container',element);
        inputs.forEach(function(input) {

            on('change', function (evt){
                let input = evt.target;

                if(input.checked){
                    let label = input.getAttribute('data-label');
                    let value = input.getAttribute('value');

                    let markupElement = document.createElement('div');

                    let quickfinderTagMarkup =
                        `<li class="list-inline-item mt-2 js-multiple-select__quickfinder-tag">` +
                        `<button class="quickfilter" type="button" data-reset-value="${value}">` +
                            `<span>${label}</span>` +
                            `<span class="quickfilter__icon icon icon-close" aria-label="remove"></span>` +
                        `</button></li>`;

                    markupElement.innerHTML += quickfinderTagMarkup;

                    let quickfinderItem = markupElement.childNodes[0];    //remove wrapper-div

                    on('click',quickfinderTagClickHandler, quickfinderItem)

                    ticketQuickfinderTagContainer.appendChild(quickfinderItem);
                }else{
                    let inputValue = input.getAttribute('value');
                    let quickfinderTags = findAllIn('.js-multiple-select__quickfinder-tag',ticketQuickfinderTagContainer);
                    quickfinderTags.forEach(tag =>{
                        if(findIn('button',tag).getAttribute('data-reset-value') === inputValue){
                            console.log('remove')
                            tag.remove();
                        }
                    });
                }

            }, input);
        });

        const quickfinderTagClickHandler = evt => {
            let quickfinderTag = evt.target;
            let resetValue = quickfinderTag.getAttribute('data-reset-value');
            inputs.forEach(input =>{
                if(input.getAttribute('value') === resetValue){
                    input.checked = false;
                    trigger('change',input);
                }
            });
        };
    });
}