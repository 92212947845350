import {onFind} from "@elements/init-modules-in-scope";
import {closest, findAll, on} from '@elements/dom-utils';
import {getApi} from "./custom-datepicker";

const selectors = {
    base: '.js-set-datepicker',
};

export function init () {
    onFind(selectors.base, function (baseElement) {
        let isSelect = baseElement.tagName === 'SELECT' || false;
        
        if(isSelect) {
            on('change', function (evt) {
                console.log(evt.target.selectedIndex);
                let selectedOption = evt.target.options[evt.target.selectedIndex];
                let newMinDate = selectedOption.dataset.setDatepickerMinDate;
                let newMaxDate = selectedOption.dataset.setDatepickerMaxDate;
                let newEnableDates = selectedOption.dataset.setDatepickerEnable;
                let targets = findAll(selectedOption.dataset.setDatepickerTarget);

                targets.forEach(function(target) {
                    setDateChanges(newMinDate, newMaxDate, newEnableDates, target, true);
                })
            }, baseElement);

        } else {
            on('click', function (evt) {
                let newMinDate = baseElement.dataset.setDatepickerMinDate;
                let newMaxDate = baseElement.dataset.setDatepickerMaxDate;
                let newEnableDates = baseElement.dataset.setDatepickerEnable;
                let targets = findAll(baseElement.dataset.setDatepickerTarget);

                targets.forEach(function(target) {
                    setDateChanges(newMinDate, newMaxDate, newEnableDates, target);
                })
                
            }, baseElement);
        }
    });
}

function setDateChanges(newMinDate, newMaxDate, newEnableDates, target, resetDate) {
    if(newMinDate || newMaxDate || newEnableDates && target) {
        Promise.all([getApi(target)]).then(function([api]) {
            let currentDate = api.getDate();
            if(newMinDate) {
                api.setMinDate(new Date(newMinDate));
            }
            if(newMaxDate) {
                api.setMaxDate(new Date(newMaxDate));
            }
            if(newEnableDates) {
                newEnableDates = JSON.parse(newEnableDates);
                api.setEnable(newEnableDates);
                if(resetDate && currentDate && !newEnableDates.includes(currentDate)) {
                    api.reset();
                }
            }
        });
    }
}