/** @jsx h */

import {onFind} from '@elements/init-modules-in-scope';
import {findIn, on} from '@elements/dom-utils';
import SkiTicketModal from "./components/ski-ticket-config/ski-ticket-modal";
import {h, render, Fragment} from "preact";
import {throwAlertMessage} from "./utils/utils";
import SkiLockerModal from "./components/ski-locker-config/ski-locker-modal";
import {transformDataset} from "./ski-ticket-config";
import {getPrefixedDataSet} from '@elements/data-set-utils';
import {assert, object, number, string, array, optional, enums, func, boolean, defaulted} from 'superstruct';


const selectors = {
    base: '.js-ticket-quickfinder-config',
    open: '.js-ticket-quickfinder-config__open',
    overlay: '.js-ticket-quickfinder-config__overlay',
    sector: '.js-ticket-quickfinder-config__sector',
    dateFrom: '.js-ticket-quickfinder-config__date-from',
    dateTo: '.js-ticket-quickfinder-config__date-to'
};

export function init() {

    const TicketQuickfinderConfig = object({
        isLoading: boolean(),
        isInEdit: optional(boolean),
        id: string(),
        isModalOpen: boolean(),
        title: string(),
        onModalHide: func(),
        onShowSkiLocker: optional(func()),
        firstView: enums(['sector-list','datepicker','pricing-list']),
        hasServiceView: boolean(),
        hasDatepickerView: boolean(),
        initiallyLoadPricingSlider: optional(boolean()),
        hasPricingSlider: boolean(),
        hasSkilockerTeaser: optional(boolean()),
        sectorDefault: optional(string()),
        sectorDefaultTitle: optional(string()),
        dateStringDefault: optional(string()),
        dateToStringDefault: optional(string()),
        dateDefault: optional(string()),
        dateToDefault: optional(string()),
        currentStepDefault: optional(number())
    })


    onFind(selectors.base, function (baseElement) {
        let button = findIn(selectors.open, baseElement);
        let overlay = findIn(selectors.overlay, baseElement);
        let sector = findIn(selectors.sector, baseElement);
        let dateFrom = findIn(selectors.dateFrom, baseElement);
        let dateTo = findIn(selectors.dateTo, baseElement);

        on('click', function (evt) {
            let stepCounter = 0;

            let dataset = getPrefixedDataSet('ticket-quickfinder-config', button);
            dataset = transformDataset(dataset);


            const renderWithData = data => render(<SkiTicketModal {...data}/>, overlay);

            /* if on last step of ski-ticket ski-locker should be display */
            const renderSkiLockerWithData = data => render(<SkiLockerModal {...data}/>, overlay);

            let data = {
                isModalOpen: true,
                onModalHide: (function () {
                    data.isModalOpen = false;
                    renderWithData(data);
                    location.reload();
                }),
                onShowSkiLocker: (function (data) {
                    data = {
                        ...data,
                        isModalOpen: true,
                        onModalHide: (function () {
                            data.isModalOpen = false;
                            renderSkiLockerWithData(data);
                            location.reload();
                        }),
                    }
                    renderSkiLockerWithData(data);
                }),
                isLoading: true
            };
            

            if(sector.value) {
                stepCounter++;
                data = {
                    ...data,
                    sectorDefaultTitle: sector.options[sector.selectedIndex].text,
                    sectorDefault: sector.value
                };
            }

            if(sector.value && dateFrom.value) {
                stepCounter++;

                data = {
                    ...data,
                    initiallyLoadPricingSlider: true,
                    ...(dateFrom.value && { dateStringDefault: dateFrom.value.substring(0, 10) }),
                    ...(dateFrom.value && { dateDefault: dateFrom.value.substring(0, 10) }),
                    ...(dateTo.value && { dateToStringDefault: dateTo.value.substring(0, 10) }),
                    ...(dateTo.value && { dateToDefault: dateTo.value.substring(0, 10) })
                };
            }

            if(sector.value && !dateTo.value && dateFrom.value) {
                data = {
                    ...data,
                    ...{dateToStringDefault: dateFrom.value.substring(0, 10)},
                    ...{dateToDefault: dateFrom.value.substring(0, 10)},
                };
            }

            data = {
                ...data,
                ...dataset,
                currentStepDefault: stepCounter,
                isLoading: false,
                isModalOpen: true
            };
            
            try {
                //validating data
                assert(data, TicketQuickfinderConfig);
                renderWithData(data);
            } catch(error) {
                console.error(error);
                for (const failure of error.failures()) {
                    let errorText = `<p><strong>ticket-quickfinder-config</strong>:Typo might be in dataset-values of div with class <strong>js-ticket-quickfinder-config</strong></p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR - ' + failure.key, errorText, true);
                }
            }


        }, button)
    });
}
